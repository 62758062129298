import React from 'react'
import { Grid } from "@mui/material";
import { CardMedia, Card, CardContent } from '@material-ui/core';
import EventwebinarTile from '../../../staticcomponents/EventwebinarTile';
import Eventswebinarlanding from '../../../assets/eventswebinarlanding.png';
import Button from '@mui/material/Button';
import './eventswebinarlandingheader.scss';
import LoadMoreButton from '../../../staticcomponents/LoadMoreButton';
import Container from '@material-ui/core/Container';

export default function EventsWebinarLandingHeader() {
    return (
        <>
            <Container maxWidth="xl" className='event-container-div'>
            <Grid container className="event-webinar-cls">
                {/* Title Starts */}
                <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
                    <Grid item xl={10} lg={10} md={10} sm={10} xs={8}>
                        <h1 className='event-title'>events & webinars</h1>
                    </Grid>
                    <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
                </Grid>
                {/* Title Ends */}
                <Grid item xl={2} lg={2} md={1} sm={1} xs={1}></Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10} className="research-container">
                    <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="event-card-container">
                        <EventwebinarTile />
                        <EventwebinarTile />
                        <EventwebinarTile />
                        <EventwebinarTile />
                    </Grid>
                </Grid>
                <Grid item xl={0} lg={0} md={1} sm={1} xs={1}></Grid>
                {/* Load More Butotn Starts */}
                <LoadMoreButton />
                {/* Load More Butotn Ends */}
            </Grid>
            </Container>
        </>
    )
}


