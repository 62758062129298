import React from 'react';
import { Grid } from "@mui/material";
import Eventswebinarlanding from '../../assets/eventswebinarlanding.png';
import { CardMedia, Card, CardContent } from '@material-ui/core';
import Button from '@mui/material/Button';
import './eventwebinarTile.scss';


export default function EventwebinarTile() {
    return (
        <>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="card-main-section">
                <Card className="event-card">
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="events-web-title">
                                    relocation playbook webinar series
                                </div>
                                <div className="events-web-paragraph">
                                    Reflecting on the findings in Cartus’ latest Relocation Playbook, our webinar invites multinational organizations Mastercard, Textron, and Westinghouse Electric Company to share their experiences of the current mobility landscape and what solutions they are implementing to meet today’s challenges.
                                </div>
                                <div>
                                    <Button variant="outlined" className='events-web-button'>replay </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="card-main-section">
                <Card className="event-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="240"
                        image={Eventswebinarlanding}
                    />
                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="events-web-title">
                                    join cartus at HRO’S talent mobility 2022
                                </div>
                                <div className="events-web-date">
                                    THURSDAY 26 MAY 2022
                                </div>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
                </div>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div className="card-main-section">
                <Card className="event-card">

                    <CardContent className="card-content">
                        <Grid item container spacing={2}>
                            <Grid item direction="column">
                                <div className="events-web-title">
                                    relocation playbook webinar series
                                </div>
                                <div className="events-web-paragraph">
                                    Reflecting on the findings in Cartus’ latest Relocation Playbook, our webinar invites multinational organizations Mastercard, Textron, and Westinghouse Electric Company to share their experiences of the current mobility landscape and what solutions they are implementing to meet today’s challenges.
                                </div>
                                <div>
                                    <Button variant="outlined" className='events-web-button'>replay </Button>
                                </div>
                            </Grid>

                        </Grid>

                    </CardContent>
                </Card>
                </div>
            </Grid>
        </>
    )
}