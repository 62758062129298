import React from 'react'
import Layout from '../../staticcomponents/layout'
import './eventswebinarlanding.scss'
import EventsWebinarLandingHeader from './eventswebinarlandingheader'

export default function EventsWebinarLanding() {

    return (
        <>
        <Layout>
        <div className='eventwebinar-landing-main'>
                <div className='capsules-bg-right'>
                    <div className='bg-donuts-eventwebinar-landing'>
                        <EventsWebinarLandingHeader />
                    </div>
                </div>
        </div>
            </Layout>
            
        </>
    )
}


